import React from 'react'
import './Footer.scss';
import { useNavigate } from 'react-router-dom';
import heart from "../assets/heart.svg";
import logo from "../assets/brand.svg";
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <footer id="mobile-footer">
            <div id="mobile-menu">
                <div id="mobile-footer-container">
                    <div className="mobile-copy">
                        <span>{t('footer.copy')}</span>
                        <img src={heart} alt={t('footer.love_alt')} style={{ width: 50, height: 50 }} />
                        <span>{t('footer.team')}</span>
                    </div>
                    <div className="mobile-link" onClick={() => navigate("/privacy-policy")}>
                        <span>{t('footer.privacy_policy')}</span>
                    </div>
                    <div className="mobile-link" onClick={() => navigate("/terms-conditions")}>
                        <span>{t('footer.terms_conditions')}</span>
                    </div>
                    <div className='mobilelogo'>
                        <span> Emprende soluciones y servicios, SL. </span>
                        <img src={logo} alt="Logo" style={{ width: 50, height: 50 }} />
                    </div>
                </div>

            </div>
        </footer>
    )
}

export default Footer
