import React from 'react';
import './App.css';
import HomePage from './pages/HomePage';
import { useCookies } from 'react-cookie';
import CookieConsent from './components/CookieConsent';
import { Route, Routes } from 'react-router-dom';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsConditions from './pages/TermsConditions';

function App() {
  const [cookies] = useCookies(["cookieConsent"]);
  return (
    <div className='App'>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/terms-conditions' element={<TermsConditions />} />
      </Routes>
      {!cookies.cookieConsent && <CookieConsent />}
    </div>
  );
}

export default App;
