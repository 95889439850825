import React from 'react'
import './PrivacyPolicy.scss';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
    const { t, i18n } = useTranslation();
    const selectedLanguage = i18n.language;

    // List of languages that should fall back to English
    const fallbackLanguages = ['cs', 'da', 'de', 'fr', 'it', 'no'];

    // Function to get the translation, falling back to English if needed
    const getTranslation = (key: string): string => {
        const translation = fallbackLanguages.includes(selectedLanguage)
            ? i18n.getFixedT('en')(key)
            : t(key);

        return typeof translation === 'string' ? translation : '';
    };

    // Ensure the return value is an array of strings
    const getArrayTranslation = (key: string): string[] => {
        const result = fallbackLanguages.includes(selectedLanguage)
            ? i18n.getFixedT('en')(key, { returnObjects: true })
            : t(key, { returnObjects: true });

        return Array.isArray(result) ? result : [];
    };

    const purposeOfProcessingList = getArrayTranslation('purpose_of_processing_list');
    const userRightsList = getArrayTranslation('user_rights_list');

    return (
        <>
            <Header />
            <div className="privacy-policy">

                <h2>{getTranslation('privacy_policy_header')}</h2>
                <p>{getTranslation('privacy_policy_intro')}</p>

                <h3>{getTranslation('purpose_of_processing')}</h3>
                <p>{getTranslation('purpose_of_processing_text')}</p>
                <ul>
                    {purposeOfProcessingList.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>

                <h3>{getTranslation('data_retention_criteria')}</h3>
                <p>{getTranslation('data_retention_criteria_text')}</p>

                <h3>{getTranslation('data_communication')}</h3>
                <p>{getTranslation('data_communication_text')}</p>

                <h3>{getTranslation('user_rights')}</h3>
                <ul>
                    {userRightsList.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>

                <h3>{getTranslation('contact_information')}</h3>
                <p>{getTranslation('contact_information_text')}</p>

                <h2>{getTranslation('mandatory_optional_information')}</h2>
                <p>{getTranslation('mandatory_optional_information_text')}</p>

                <h2>{getTranslation('security_measures')}</h2>
                <p>{getTranslation('security_measures_text')}</p>
            </div>
            <Footer />
        </>
    );
}


export default PrivacyPolicy
