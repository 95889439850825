
import React, { useEffect, useState } from 'react'
import './Services.scss';
import { ReactComponent as Icon } from '../assets/circleicon.svg';
import { useTranslation } from 'react-i18next';

const Services = () => {
    const { t } = useTranslation();
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div id='nuestro-servicio' className="services">
            <section className="features">
                <h2>{t('services.featuresTitle')}</h2>
                <div className="features-cards">
                    <div className="card">
                        <Icon className="icon" />
                        <h3>{t('services.featuresCards.alwaysWithYou.title')}</h3>
                        <p>{t('services.featuresCards.alwaysWithYou.description')}</p>
                    </div>
                    <div className="card">
                        <Icon className="icon" />
                        <h3>{t('services.featuresCards.fairPrices.title')}</h3>
                        <p>{t('services.featuresCards.fairPrices.description')}</p>
                    </div>
                    <div className="card">
                        <Icon className="icon" />
                        <h3>{t('services.featuresCards.homeCare.title')}</h3>
                        <p>{t('services.featuresCards.homeCare.description')}</p>
                    </div>
                    <div className="card">
                        <Icon className="icon" />
                        <h3>{t('services.featuresCards.accessReports.title')}</h3>
                        <p>{t('services.featuresCards.accessReports.description')}</p>
                    </div>
                </div>
            </section>

            <section className="instructions">
                <h2>{t('services.serviceTitle')}</h2>
                <p className='description'>{t('services.serviceDescription')}</p>
                <div className="steps">
                    {isMobile ? (
                        <div className="steps-mobile">
                            <div className="card">
                                <Icon className="icon" />
                                <h3>{t('services.stepsCards.registerApp.title')}</h3>
                                <p>{t('services.stepsCards.registerApp.description')}</p>
                            </div>
                            <div className="card">
                                <Icon className="icon" />
                                <h3>{t('services.stepsCards.triage.title')}</h3>
                                <p>{t('services.stepsCards.triage.description')}</p>
                            </div>
                            <div className="card">
                                <Icon className="icon" />
                                <h3>{t('services.stepsCards.homeVisits.title')}</h3>
                                <p>{t('services.stepsCards.homeVisits.description')}</p>
                            </div>
                            <div className="card">
                                <Icon className="icon" />
                                <h3>{t('services.stepsCards.payEasily.title')}</h3>
                                <p>{t('services.stepsCards.payEasily.description')}</p>
                            </div>
                            <div className="card">
                                <Icon className="icon" />
                                <h3>{t('services.stepsCards.downloadReports.title')}</h3>
                                <p>{t('services.stepsCards.downloadReports.description')}</p>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="steps-left">
                                <div className="card">
                                    <Icon className="icon" />
                                    <h3>{t('services.stepsCards.registerApp.title')}</h3>
                                    <p>{t('services.stepsCards.registerApp.description')}</p>
                                </div>
                                <div className="card">
                                    <Icon className="icon" />
                                    <h3>{t('services.stepsCards.homeVisits.title')}</h3>
                                    <p>{t('services.stepsCards.homeVisits.description')}</p>
                                </div>
                                <div className="card">
                                    <Icon className="icon" />
                                    <h3>{t('services.stepsCards.downloadReports.title')}</h3>
                                    <p>{t('services.stepsCards.downloadReports.description')}</p>
                                </div>
                            </div>
                            <div className="steps-divider">
                                <div className="circle"></div>
                            </div>
                            <div className="steps-right">
                                <div className="card">
                                    <Icon className="icon" />
                                    <h3>{t('services.stepsCards.triage.title')}</h3>
                                    <p>{t('services.stepsCards.triage.description')}</p>
                                </div>
                                <div className="card">
                                    <Icon className="icon" />
                                    <h3>{t('services.stepsCards.payEasily.title')}</h3>
                                    <p>{t('services.stepsCards.payEasily.description')}</p>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </section>
        </div>
    )
}

export default Services