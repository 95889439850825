import React from 'react';
import "./HomePage.scss";
import Header from '../components/Header';
import Banner from '../components/Banner';
import Services from '../components/Services';
import Feedback from '../components/Feedback';

const HomePage = () => {
    return (
        <div className="home-page">
            <Header />
            <Banner />
            <Services />
            <Feedback />
        </div>
    );
};

export default HomePage